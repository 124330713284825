<template>
  <div class="q-pa-md">
    <div class="main-content q-pa-md">
      <q-form ref="myForm" @submit="AddOrUpdateUser">
        <Mural
          :breadcrumbs="breadcrumbs"
          route="users"
          :query="{}"
          class="q-mb-md"
        >
          <FormButton v-if="userId != null" titleButton="Salvar" />
          <FormButton v-else titleButton="Criar" />
        </Mural>
        <div class="row no-wrap">
          <div v-if="isMaster">
            <label for="">É Master</label>
            <q-toggle v-model="master" color="green" />
          </div>
          <div>
            <label for="">Status</label>
            <q-toggle
              v-model="stateUser"
              color="green"
              :disable="!validateActionsPage('users-edit')"
            />
          </div>
          <div>
            <label for="">É Líder</label>
            <q-toggle
              v-model="isLider"
              color="green"
              :disable="!validateActionsPage('users-edit')"
            />
          </div>
        </div>
        <div class="container-inputs">
          <div class="form-group">
            <label for="" class="label-input">Nome</label>
            <q-input
              v-model="first_name"
              type="text"
              dense
              outlined
              placeholder="digite aqui"
              color="default-pink"
              bg-color="grey-3"
              :disable="!validateActionsPage('users-edit')"
              :rules="[(val) => !!val || 'O campo Nome é obrigatório.']"
            />
          </div>
          <div class="form-group">
            <label for="" class="label-input">Sobrenome</label>
            <q-input
              v-model="lastname"
              type="text"
              dense
              outlined
              placeholder="digite aqui"
              color="default-pink"
              bg-color="grey-3"
              :disable="!validateActionsPage('users-edit')"
              :rules="[(val) => !!val || 'O campo Sobrenome é obrigatório.']"
            />
          </div>
        </div>
        <div class="container-inputs">
          <div class="form-group">
            <div class="container-email">
              <div class="item-email-input">
                <label for="" class="label-input">E-mail/Matrícula</label>
                <q-input
                  v-model="email"
                  :type="email_valid ? 'email' : 'text'"
                  dense
                  outlined
                  placeholder="digite aqui"
                  color="default-pink"
                  bg-color="grey-3"
                  :disable="!validateActionsPage('users-edit')"
                  :rules="[(val) => !!val || 'O campo Email é obrigatório.']"
                />
              </div>
              <div>
                <label for="">Validar E-mail</label>
                <q-toggle
                  v-model="email_valid"
                  color="green"
                  :disable="!validateActionsPage('users-edit')"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="" class="label-input">Perfil</label>
            <q-select
              v-model="profile"
              :options="listProfiles"
              type="text"
              dense
              map-options
              outlined
              emit-value
              placeholder="digite aqui"
              color="default-pink"
              bg-color="grey-3"
              :disable="!validateActionsPage('users-edit')"
              :rules="[(val) => !!val || 'O campo Perfil é obrigatório.']"
            />
          </div>
        </div>
        <div class="container-inputs">
          <div class="form-group">
            <label for="">Líder</label>
            <q-select
              bg-color="grey-3"
              color="default-pink"
              class="col-11"
              v-model="lider"
              use-input
              :disable="!validateActionsPage('users-edit')"
              input-debounce="0"
              :options="listUsers"
              emit-value
              map-options
              outlined
              dense
              @filter="filterFnUsers"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt.label" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="form-group" v-if="isMaster">
            <label for="" class="label-input">Empresa</label>
            <q-select
              bg-color="grey-3"
              color="default-pink"
              outlined
              dense
              v-model="company_id"
              use-input
              :disable="!validateActionsPage('users-edit')"
              input-debounce="0"
              :options="listCompanies"
              emit-value
              @update:model-value="filterGroupByCompany()"
              map-options
              :rules="[(val) => !!val || 'O campo Empresa é obrigatório.']"
              @filter="filterFnUsers"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt.label" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
        <div class="container-user-groups">
          <div class="item-user-groups">
            <label for="">Selecione um segmento</label>
            <q-select
              bg-color="grey-3"
              color="default-pink"
              outlined
              dense
              multiple
              use-chips
              :disable="!validateActionsPage('users-edit')"
              use-input
              emit-value
              map-options
              @filter="filterFnBranches"
              v-model="branches"
              :options="listBranches"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="container-groups">
            <div class="item-groups item-user-groups">
              <label for="" class="label-input">Grupos</label>
              <q-select
                v-model="groups"
                :options="_optionListGroups"
                @filter="filterFnGroups"
                bg-color="grey-3"
                color="default-pink"
                :disable="!validateActionsPage('users-edit')"
                outlined
                dense
                multiple
                use-chips
                use-input
                emit-value
                map-options
                :rules="[
                  (val) => !val.length == 0 || 'O campo grupos é obrigatório',
                ]"
                ><template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Não encontrado
                    </q-item-section>
                  </q-item>
                </template></q-select
              >
            </div>
            <q-checkbox
              left-label
              :disable="!validateActionsPage('users-edit')"
              v-model="allGroups"
              label="Selecionar todos"
              color="default-pink"
              @update:model-value="selectedFilteredGroups"
            />
          </div>
        </div>
        <div class="container-inputs">
          <div class="form-group">
            <label for="" :class="{ 'label-input': !isNewUser }">
              {{ userId != null ? "Nova Senha" : "Senha" }}</label
            >
            <q-input
              v-model="password"
              :type="isPassword ? 'password' : 'text'"
              :disable="
                !validateActionsPage('users-edit') &&
                !validateActionsPage('users-reset-password')
              "
              bg-color="grey-3"
              color="default-pink"
              outlined
              dense
              ><template v-slot:append>
                <q-icon
                  :name="isPassword ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPassword = !isPassword"
                /> </template
            ></q-input>
          </div>
          <div class="form-group">
            <label for="" :class="{ 'label-input': !isNewUser }">
              Confirmar senha</label
            >
            <q-input
              v-model="confirmPassword"
              :type="isPassword ? 'password' : 'text'"
              :disable="
                !validateActionsPage('users-edit') &&
                !validateActionsPage('users-reset-password')
              "
              bg-color="grey-3"
              color="default-pink"
              outlined
              dense
              :rules="[
                (val) =>
                  val == password ||
                  `A confirmação da senha tem que ser igual a  ${
                    userId != null ? 'nova Senha' : 'senha'
                  }`,
              ]"
              ><template v-slot:append>
                <q-icon
                  :name="isPassword ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPassword = !isPassword"
                /> </template
            ></q-input>
          </div>
        </div>

        <q-inner-loading
          :showing="loadingPage"
          label="Por favor aguarde..."
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />
      </q-form>
      <section
        class="permission-table q-px-xl q-mt-md"
        v-if="validateActionsPage('users-edit') && userId != null"
      >
        <div class="flex row justify-between items-baseline q-mb-md">
          <h5>Permissões</h5>
          <small>Status</small>
        </div>
        <div>
          <div
            class="item-permition flex row justify-between items-center q-px-sm q-mb-sm"
            v-for="permission in permissons"
            :key="permission.permission_id"
          >
            <h5>{{ permission.display_name }}</h5>
            <q-toggle
              v-model="permission.permission_enabled"
              :false-value="False"
              color="green"
              :disable="isAdmin && permission.permission_type === 'roles'"
            />
          </div>
          <DefaultBtn
            titleButton="Salvar Alterações"
            :showIcon="false"
            @click="(event) => tooglePermissions(event)"
          />
        </div>
      </section>
    </div>
  </div>
</template>
<script>
//----COMPONENTS
import Mural from "@/components/Mural.vue";
import FormButton from "@/components/admin/buttons/FormButton.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputText from "@/components/admin/inputs/InputText.vue";
import InputSelect from "@/components/admin/inputs/InputSelect.vue";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import RoleServices from "@/services/RoleServices";
import CompanyServices from "@/services/CompanyServices";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

/* vuex */
import { useStore } from "vuex";

export default {
  name: "NewUser",
  components: {
    Mural,
    FormButton,
    InputText,
    InputSelect,
    DefaultBtn,
  },
  setup() {
    //----CONSTANTES
    const breadcrumbs = ref(["Novo usuário"]);
    const router = new useRouter();
    const route = new useRoute();
    const $q = new useQuasar();
    const canUserAccessClass = new CanUserAccessClass();
    const store = new useStore();

    //----SERVICE
    const RoleService = new RoleServices();
    const GroupService = new GroupServices();
    const UserService = new UserServices();
    const companyServices = new CompanyServices();





    //----VARIABLES
    let myForm = ref(null);
    let first_name = ref("");
    let lastname = ref("");
    let email = ref("");
    let password = ref("");
    let confirmPassword = ref("");
    let userId = ref(null);
    let groups = ref([]);
    let profile = ref(null);
    let lider = ref(null);
    let company_id = ref(null);
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let allGroups = ref(false);
    let branches = ref([]);

    let listCompanies = ref([]);
    let listUsers = ref([]);
    let optionListUsers = ref([]);
    let listGroups = ref([]);
    let _optionListGroups = ref([]);
    let permissons = ref([]);
    let optionListGroupsByCompany = ref([]);
    let listProfiles = ref([]);
    let loadingPage = ref(false);

    let userData = ref(null);
    let isMaster = ref(false);
    let isLider = ref(false);
    let email_valid = ref(true);
    let master = ref(false);
    let stateUser = ref(true);

    const isAdmin = computed(()=> profile.value === 1)

    async function _listAllRoles() {
      RoleService.showRoles().then((response) => {
        response.data.map((item) => {
          listProfiles.value.push({
            label: item.name,
            value: item.id,
          });
        });
      });
    }

    async function getBranches() {
      await GroupService.listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function _listAllGroups() {
      loadingPage.value = true;
      GroupService.listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          _optionListGroups.value = listGroups.value;
          optionListGroupsByCompany.value = listGroups.value;
          loadingPage.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _listAllUsers() {
      loadingPage.value = true;
      await UserService.getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
          optionListUsers.value = listUsers.value;
          loadingPage.value = false;
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _listAllUsersPermissions() {
      await UserService.getPermissions(userId.value)
        .then((response) => {
          permissons.value = response.data;
        })
        .catch((e) => {
          console.log("Algo deu errado " + e);
        });
    }

    async function _listAllCompanies() {
      loadingPage.value = true;
      await companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
          loadingPage.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        _optionListGroups.value.filter((group) => {
          if (!groups.value.includes(group.value)) {
            groups.value.push(group.value);
          }
        });
      } else {
        groups.value = null;
        groups.value = [];
      }
    }

    async function GetUser(userid) {
      if (userid != null) {
        loadingPage.value = true;
        let response = await UserService.getUserbyId(userid);
        first_name.value = response.data.first_name;
        lastname.value = response.data.last_name;
        email.value = response.data.email;
        lider.value = response.data.lider == 0 ? null : response.data.lider;
        isLider.value = response.data.is_leader;
        profile.value = response.data.role_id;
        company_id.value = response.data.company_Id;
        stateUser.value = response.data.active == 1 ? true : false;
        master.value = response.data.master == 1 ? true : false;
        email_valid.value = response.data.email_valid;
        response.data.groups.forEach((group) => {
          groups.value.push(group.group);
        });
        response.data.branches_id.forEach((id) => branches.value.push(id));
        if (isMaster.value) {
          _filterGroupsWithCompanies();
        }

        loadingPage.value = false;
      }
    }

    onMounted(() => {
      userId.value = route.query.id;
      userData.value = JSON.parse(localStorage.getItem("vuex")).userdata;
      isMaster.value = userData.value.master == 1;

      if (userId.value != null) {
        breadcrumbs.value[0] = "Editar usuário";
        _listAllUsersPermissions();
      }
      _listAllGroups();
      _listAllRoles();
      _listAllUsers();
      if (isMaster.value) {
        _listAllCompanies();
      }
      GetUser(userId.value);
      getBranches();
    });

    const isNewUser = computed(() => userId.value != null);

    watch(branches, (newBranch, oldBranch) => {
      if (newBranch.length == 0) {
        groups.value = null;
        groups.value = [];
        allGroups.value = false;
      }

      if (newBranch.length != oldBranch.length) {
        _optionListGroups.value = listGroups.value;
      }

      if (newBranch.length != 0) {
        _optionListGroups.value = listGroups.value.filter((group) => {
          return newBranch.includes(group.branch_id);
        });

        let groupValues = _optionListGroups.value.map((group) => {
          return group.value;
        });

        groups.value = groupValues.filter((value) => {
          if (groups.value.includes(value)) {
            return value;
          }
        });
      }
    });

    watch(groups, (newGroup, oldGroup) => {
      if (newGroup.length == 0) {
        allGroups.value = false;
      }
    });

    watch(_optionListGroups, (newGroup, oldGroup) => {
      if (allGroups.value && branches.value.length != 0) {
        if (newGroup.length > oldGroup.length) {
          _optionListGroups.value.filter((group) => {
            if (!groups.value.includes(group.value)) {
              groups.value.push(group.value);
            }
          });
        }
      }
    });

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function _treatmentFormDataUser() {
      const formData = new FormData();
      formData.append("first_name", first_name.value);
      formData.append("Last_name", lastname.value);
      formData.append("Email", email.value);
      formData.append("Avatar_type", null);
      formData.append("Avatar_location", null);
      formData.append("Image", null);
      formData.append("Role_id", profile.value);
      formData.append("Lider", lider.value == null ? 0 : lider.value);
      formData.append("is_leader", isLider.value);
      formData.append("Password", password.value);
      formData.append("Password_confirmed", confirmPassword.value);
      formData.append("Email_valid", email_valid.value);
      formData.append("Status", stateUser.value ? 1 : 0);
      formData.append("Master", master.value ? 1 : 0);

      if (company_id.value == null) {
        formData.append("Company_Id", store.state.userdata.company_id);
      } else {
        formData.append("Company_Id", company_id.value);
      }

      groups.value.forEach((group) => {
        formData.append("Groups[]", group);
      });

      return formData;
    }

    async function AddOrUpdateUser() {
      if (!(await myForm.value.validate())) return;

      const form = _treatmentFormDataUser();
      if (userId.value == null) {
        await _addUser(form);
      } else {
        await _updateUser(form);
      }
    }

    async function _addUser(formData) {
      loadingPage.value = true;
      await UserService.createNewUser(formData)
        .then((response) => {
          $q.notify({
            message: "Usuário criado com sucesso.",
            color: "green-9",
            position: "top",
          });
          loadingPage.value = false;
          router.push({ name: "users" });
        })
        .catch((error) => {
          $q.notify({
            message: error.response.data.toString(),
            color: "red-9",
            position: "top",
          });
          loadingPage.value = false;
        });
    }

    async function _updateUser(formData) {
      loadingPage.value = true;
      await UserService.updateUserbyId(userId.value, formData)
        .then(() => {
          $q.notify({
            message: "Usuário alterado com sucesso.",
            color: "green-9",
            position: "top",
          });
          loadingPage.value = false;
          router.push({ name: "users" });
        })
        .catch((error) => {
          $q.notify({
            message: error.response.data.toString(),
            color: "red-9",
            position: "top",
          });
          loadingPage.value = false;
        });
    }

    async function tooglePermissions(event) {
      event.preventDefault();


      const activePermissionsUser = permissons.value.filter((permission) => {
        return permission.permission_enabled === true && permission.permission_type === "users";
      });

      const unActivePermissionRoles = permissons.value.filter((permission)=>{
        return permission.permission_enabled === false && permission.permission_type === "roles";
      })


      const alteredPermissions = activePermissionsUser.concat(unActivePermissionRoles)


     const form = {
        permissions_list: alteredPermissions
      }

      if (userId.value !== null) {
        await UserService.tooglePermission(userId.value, form)
          .then((response) => {
            if (response.data){
              $q.notify({
                message: "Permissões alteradas com sucesso.",
                color: "green-9",
                position: "top",
              });
            }
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
          });
      }
    }

    function filterFnUsers(val, update) {
      if (val === "") {
        update(() => {
          listUsers.value = optionListUsers.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listUsers.value = optionListUsers.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function _filterGroupsWithCompanies() {
      listGroups.value = optionListGroupsByCompany.value.filter(
        (v) => v.company_id == company_id.value
      );
      optionListBranches.value = optionListBranches.value.filter(
        (branch) => (branch.company_id = company_id.value)
      );
      _optionListGroups.value = listGroups.value;
      listBranches.value = optionListBranches.value;
    }

    function filterGroupByCompany() {
      _optionListGroups.value = null;
      listBranches.value = null;
      _filterGroupsWithCompanies();
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (branches.value.length > 0) {
            _optionListGroups.value = listGroups.value.filter((group) => {
              return branches.value.includes(group.branch_id);
            });
          }
          _optionListGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        _optionListGroups.value = _optionListGroups.value.filter((v) => {
          if (branches.value.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              branches.value.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    return {
      //----VARIABLES
      loadingPage,
      isPassword: ref(true),
      isConfPass: ref(true),
      isLider,
      password,
      confirmPassword,
      userId,
      profile,
      lider,
      listProfiles,
      groups,
      first_name,
      lastname,
      email,
      email_valid,
      listGroups,
      permissons,
      listBranches,
      allGroups,
      _optionListGroups,
      branches,
      stateUser,
      listUsers,
      company_id,
      listCompanies,
      breadcrumbs,
      isMaster,
      master,
      validateActionsPage,
      isNewUser,
      myForm,
      isAdmin,

      //----FUNCTIONS
      AddOrUpdateUser,
      filterGroupByCompany,
      filterFnBranches,
      filterFnUsers,
      tooglePermissions,
      filterFnGroups,
      selectedFilteredGroups,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  height: 93vh;
  overflow-y: scroll;
}

.permission-table {
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  padding: 13px 35px;
  > div {
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #000000;
    }
    small {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: right;

      color: #686868;
    }
  }
  .item-permition {
    border: 1px solid #e5e5e5;
    border-radius: 8px;

    h5 {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      color: #686868;
    }
  }
}

.container-inputs {
  display: flex;
  justify-content: space-between;
}

.form-group {
  width: calc(50% - 24px);
}

:is(.label-input, label) {
  color: #666666;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-bottom: 4px;
}

.label-input::after {
  content: " *";
  color: #cc0000;
}

.container-email {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item-email-input {
  min-width: 50%;
  width: calc(90% - 10px);
}

.container-groups {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.item-groups {
  width: calc(80% - 10px);
}

.container-user-groups {
  display: flex;
  flex-direction: column;
}

:is(.item-user-groups, .item-password) {
  margin-top: 26px;
}
</style>
